<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center">
			<v-col md="4" sm="8" lg="3">
				<div class="mb-5" flat>
					<p><v-img :src="require('@/assets/gctu_logo.png')"></v-img></p>

					<p class="font-weight-medium text-center">STAFF INFORMATION PORTAL</p>
				</div>
				<v-card class="align-center">
					<div class="text-center">
						<v-alert
							v-if="IsErrors"
							text
							prominent
							type="error"
							v-text="errors"
						/>
					</div>

					<v-card-text>
						<ValidationObserver ref="observer" tag="form" v-slot="{ validate }">
							<v-form ref="form" @submit.prevent class="mt-3">
								<ValidationProvider
									v-slot="{ errors }"
									name="Email"
									rules="required|email"
								>
									<v-text-field
										outlined
										clearable
										class="rounded-0"
										v-model="email"
										prepend-inner-icon="mdi-account"
										label="Username"
										type="email"
										:error-messages="errors"
										required
										@focus="removeErrors"
									/>
								</ValidationProvider>
								<ValidationProvider
									v-slot="{ errors }"
									name="Password"
									rules="required"
								>
									<v-text-field
										outlined
										clearable
										ref="password"
										v-model="passwrd"
										prepend-inner-icon="mdi-lock"
										label="Password"
										:append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
										:error-messages="errors"
										:type="show3 ? 'text' : 'password'"
										name="input-10-2"
										class="input-group--focused rounded-0"
										@keyup.enter="btnSignin"
										@click:append="show3 = !show3"
									/>
								</ValidationProvider>

								<v-card-actions>
									<v-spacer />
									<v-scale-transition>
										<v-btn
											class="px-8"
											color="primary"
											:loading="loading"
											@click="validate().then(btnSignin)"
										>
											Login
										</v-btn>
									</v-scale-transition>
								</v-card-actions>
							</v-form>
						</ValidationObserver>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { computed, getCurrentInstance, reactive, toRefs } from "vue";
	import { useActions, useGetters } from "vuex-composition-helpers";
	// import { uuid } from "vue-uuid";
	import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
	import { required, email } from "vee-validate/dist/rules";
	extend("required", {
		...required,
		message: "{_field_} cannot be empty",
	});
	extend("email", {
		...email,
		message: "{_field_} is required",
	});
	export default {
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		setup() {
			const vm = getCurrentInstance();
			const { authenticated, user, getters_authorized } = useGetters([
				"authenticated",
				"user",
				"getters_authorized",
			]);
			const { signIn } = useActions(["signIn"]);

			const formData = reactive({
				loading: false,
				email: "",
				passwrd: "",
				device: "browser",
				errors: [],
				show3: false,
				rules: [(value) => !!value || "Required."],
				getdata: null,
			});

			const { errors, rules, show3, getdata, loading } = toRefs(formData);

			// let namespace = ref("65f9af5d-f23f-4065-ac85-da725569fdcd");

			const IsErrors = computed(() => {
				return errors.value.length > 0;
			});

			const btnSignin = () => {
				let email = formData.email;
				let password = formData.passwrd;
				errors.value = [];
				getdata.value = vm.proxy.$refs.observer.validate().then((result) => {
					if (result) {
						loading.value = true;
						signIn({ email, password })
							.then(() => {
								if (user && authenticated.value && getters_authorized.value) {
									vm.proxy.$router.push({
										path: vm.proxy.$route.query.redirect || `/home`,
									});
								} else {
									errors.value = "YOUR ACCOUNT IS DEACTIVATED";
								}
							})
							.catch((error) => {
								//form.value.reset();
								switch (error.response.status) {
									case 422:
										errors.value = "USER CREDENTIALS INVALID";
										break;

									default:
										errors.value = "KINDLY CHECK YOUR CONNECTIONS";
										break;
								}
							})
							.finally(() => {
								loading.value = false;
								vm.proxy.$refs.form.reset();
							});
					}
				});
			};

			const removeErrors = () => {
				errors.value = [];
			};

			return {
				removeErrors,
				btnSignin,
				rules,
				loading,
				...toRefs(formData),
				errors,
				show3,
				IsErrors,
			};
		},
	};
</script>

<style></style>
